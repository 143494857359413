const projectData = [
  {
    "_id": "6573cf28527000eccfc2e1234",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/APTO-EBANO/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/APTO-EBANO/RENDER PRINCIPAL.jpg",
      "projects/APTO-EBANO/2.jpg",
      "projects/APTO-EBANO/3.jpg",
      "projects/APTO-EBANO/4.jpg",
      "projects/APTO-EBANO/15.jpg",
      "projects/APTO-EBANO/5.jpg",
      "projects/APTO-EBANO/6.jpg",
      "projects/APTO-EBANO/7.jpg",
      "projects/APTO-EBANO/8.jpg",
      "projects/APTO-EBANO/9.jpg",
      "projects/APTO-EBANO/10.jpg",
      "projects/APTO-EBANO/11.jpg",
      "projects/APTO-EBANO/12.jpg",
      "projects/APTO-EBANO/13.jpg"
    ],
    "company": "APTO ÉBANO - LG",
    "about": [
      "Um apartamento térreo compacto e moderno, com varanda estendida e uma área social integrada com ponto de destaque para a ilha em pedra preta. Ao longo do apartamento, alguns elementos pontuais foram trabalhados no amadeirado preto para compor com a pedra das bancadas, trazendo ousadia, alto contraste e modernidade ao projeto. A harmonia visual é crucial, e mesmo em um projeto ousado é importante que o espaço seja convidativo e confortável, portanto, em meio aos tons neutros, o caramelo utilizado em detalhes aquece na medida certa. A área social integrada e compacta foi pensada para atender o dia a dia do casal visando a funcionalidade e o conforto.",
      "A paleta neutra segue nos revestimentos, papel de parede e móveis em todos os outros ambientes. A suíte master agrega à paleta o verde, cor que transmite calma."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e1e7",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/CLINICA-CORUMBA/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/CLINICA-CORUMBA/RENDER PRINCIPAL.jpg",
      "projects/CLINICA-CORUMBA/3.jpg",
      "projects/CLINICA-CORUMBA/4.jpg",
      "projects/CLINICA-CORUMBA/5.jpg",
      "projects/CLINICA-CORUMBA/6.jpg",
      { url: "projects/CLINICA-CORUMBA/2.jpg", wide: true }
    ],
    "company": "CLÍNICA CORUMBÁ",
    "about": [
      "A proposta desse projeto foi modernizar a recepção nas formas e nos acabamentos, com tons claros para trazer leveza além de um dos tons da logo da clínica para reforçar a identidade da marca.",
      "O revestimento branco com relevo aplicado sobre elementos curvos reforça a organicidade do projeto, aplicado no balcão, nos brises, nos bancos e na bancada de café.",
      "Ainda nos elementos curvos, trabalhamos com boiseries que dispõem de um mural para registrar os pets pacientes da clínica. E os brises que, isolam parcialmente a espera para os tutores de felinos."
    ]
  },
  {
    "_id": "6573cf2881a8bc51b516aaf0",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/SUITE-FUNGI/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-FUNGI/RENDER PRINCIPAL.jpg",
      "projects/SUITE-FUNGI/2.jpg",
      "projects/SUITE-FUNGI/4.jpg",
      "projects/SUITE-FUNGI/5.jpg",
      "projects/SUITE-FUNGI/6.jpg",
      "projects/SUITE-FUNGI/1.jpg",
      "projects/SUITE-FUNGI/7.jpg"
    ],
    "company": "SUÍTE FUNGI - BF",
    "about": [
      "Delicadeza em cada pequeno detalhe resultou nesse ambiente infantil leve e gracioso. Tons claros com rosa bebê trabalhados na marcenaria, no revestimento e papel de parede. Cama baixinha com um pórtico curvo em marcenaria traz um aconchego, juntamente com a iluminação suave da tela tensionada. A ludicidade se apresenta sutilmente com elementos do tema da suíte e na decoração. Mesa de estudos com espelho para uso de penteadeira."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e1dfgh",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/COZINHA-MN/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/COZINHA-MN/RENDER PRINCIPAL.jpg",
      "projects/COZINHA-MN/2.jpg",
      "projects/COZINHA-MN/3.jpg"
    ],
    "company": "COZINHA MN",
    "about": [
      "Tornando a cozinha em um espaço que se integra à sala de jantar e estar com a ilha e banquetas, ótimo para receber em casa. Bastante espaço de bancada e funcionalidade para essa cozinha, trabalhando com tons claros para ficar aconchegante."
    ]
  },
  {
    "_id": "65e3a53060749ad059a843a2",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/APTO-MONO/RENDER PRINCIPAL.png",
    "pictures": [
      "projects/APTO-MONO/RENDER PRINCIPAL.png",
      "projects/APTO-MONO/02.png",
      "projects/APTO-MONO/03.png",
      "projects/APTO-MONO/04.png",
      "projects/APTO-MONO/05.png",
      "projects/APTO-MONO/06.png",
      "projects/APTO-MONO/11.png",
      "projects/APTO-MONO/12.png",
      "projects/APTO-MONO/08.png",
      "projects/APTO-MONO/09.png",
      "projects/APTO-MONO/10.png"
    ],
    "company": "APTO MONOCROMO - SF",
    "about": [
      "A diretriz desse projeto foi a paleta monocromática no tom fendi.Utilizando esse tom em diferentes texturas, os elementos do espaço se unem de maneira coesa, criando uma sensação de harmonia e unidade visual.Aplicada na madeira, em tecidos, no mármore, na decoração, a cor fendi resultou em um ambiente simplificado e moderno.",
      "A aplicação equilibrada de texturas e padrões foram de extrema importância para enriquecer e somar a estética desse projeto monocromático.",
      "Ao entrar no apartamento, a sala de estar integrada com a sala de jantar.O muxarabi foi usado para segregar suavemente o espaço da churrasqueira.Também para dividir os espaços, portas envidraçadas isolam a cozinha.",
      "O ponto de cor vem de maneira ousada e sofisticada no mármore da cuba esculpida do lavabo.Ambiente trabalhado com muitas texturas e iluminação pontual.",
      "A suíte master segue a mesma proposta de paleta monocromática, texturas e padrões.A cabeceira da cama é composta por uma base estofada, um painel em muxarabi e um pórtico em espelho bronze.Ao lado da cama, uma penteadeira embutida na cabeceira e logo a frente da cama, um armário espelhado com TV embutida."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e18yhg",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/ESTETICA-FB/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/ESTETICA-FB/RENDER PRINCIPAL.jpg",
      "projects/ESTETICA-FB/2.jpg",
      "projects/ESTETICA-FB/3.jpg",
      "projects/ESTETICA-FB/4.jpg",
      { url: "projects/ESTETICA-FB/5.jpg", wide: true },
      "projects/ESTETICA-FB/6.jpg",
      "projects/ESTETICA-FB/7.jpg"
    ],
    "company": "ESTÉTICA FB",
    "about": [
      "Um ambiente convidativo, funcional, tranquilo e confortável para receber e atender. Essas foram as diretrizes para esse projeto de estética, na qual trabalhamos com tons neutros claros e suaves com um toque de cor.",
      "Logo na entrada, a recepção com espaço para café e poltronas para a espera. Ao lado as áreas de manicure e de maquiagem, além de uma sala de atendimentos privativa. Muita delicadeza e feminilidade nessa sala de estética linda."
    ]
  },
  {
    "_id": "6573cf28ea6ebccad18b847c",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/SUITE-OFFICE/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-OFFICE/RENDER PRINCIPAL.jpg",
      "projects/SUITE-OFFICE/2.jpg",
      "projects/SUITE-OFFICE/3.jpg",
      "projects/SUITE-OFFICE/4.jpg"
    ],
    "company": "SUÍTE OFFICE - DT",
    "about": [
      "Uma suíte elegante e moderna em uma paleta mais escura com tons quentes, e um espaço voltado ao home office. Nesse projeto, a cama juntamente à escrivaninha são centrais ao quarto, tendo um grande destaque, por isso trabalhamos com uma base de cama e cabeceira em mármore. A iluminação aconchegante da tela tensionada vem de dentro do armário através do muxarabi trabalhado na marcenaria."
    ]
  },
  {
    "_id": "661c0860373acce8278a4538",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/SUITE-ROSE/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-ROSE/1.jpg",
      "projects/SUITE-ROSE/2.jpg",
      "projects/SUITE-ROSE/RENDER PRINCIPAL.jpg",
      "projects/SUITE-ROSE/4.jpg",
      "projects/SUITE-ROSE/5.jpg",
      "projects/SUITE-ROSE/6.jpg",
      "projects/SUITE-ROSE/7.jpg",
      "projects/SUITE-ROSE/8.jpg"
    ],
    "company": "SUÍTE ROSE - EC",
    "about": [
      "Nos tons claros offwhite e rosa com detalhes em dourado, essa suíte transborda delicadeza, sofisticação e vaidade.",
      "Priorizamos espaço de armário e uma penteadeira muito iluminada, para acomodar todas suas roupas, bolsas, maquiagens e perfumes. As portas em reflecta bronze permitem que a iluminação interna do armário seja visível por fora, assim como todas as gavetas revestidas em couro.",
      "O quadro e o pendente reforçam a linguagem delicada do quarto, assim como a roupa de cama na tonalidade rosa.",
      "O quarto conta com um painel movie no espelho da penteadeira e com automação em toda iluminação, som ambiente, persiana e ar split.",
      "Seguindo a mesma paleta do quarto, o banheiro também é delicado e possui muito espaço de armário espelhado com iluminação. Uma linda bancada esculpida em mármore com armário inferior com puxadores personalizados no dourado. Um nicho iluminado para produtos de banho e uma parede em espelho bronze com a porta oculta que vai para o quarto."
    ]
  },
  {
    "_id": "6573cf28e041f6e8fc807e81",
    "isInHome": true,
    "released": true,
    "mainPicture": "projects/APTO-GRIS/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/APTO-GRIS/RENDER PRINCIPAL.jpg",
      "projects/APTO-GRIS/3.jpg",
      "projects/APTO-GRIS/4.jpg",
      "projects/APTO-GRIS/13.jpg",
      "projects/APTO-GRIS/7.jpg",
      "projects/APTO-GRIS/2.jpg",
      "projects/APTO-GRIS/5.jpg",
      "projects/APTO-GRIS/6.jpg",
      "projects/APTO-GRIS/8.jpg",
      "projects/APTO-GRIS/14.jpg",
      "projects/APTO-GRIS/9.jpg",
      "projects/APTO-GRIS/10.jpg",
      "projects/APTO-GRIS/11.jpg",
      "projects/APTO-GRIS/12.jpg",
    ],
    "company": "APTO GRIS - OC",
    "about": [
      "Explorando a paleta de tons quentes e escuros, esse projeto transmite uma atmosfera calorosa e de aconchego, juntamente com uma elegância e contemporaneidade dada pelas formas e integração de toda a área social. A iluminação fortalece a linguagem de aconchego sendo mais pontual, perimetral e indireta.",
      "Logo no hall de acesso uma caixa de vidro com um vaso vietnamita com galhos secos isola parcialmente a visão da cozinha ao entrar no apartamento.",
      "No gourmet uma porta oculta a churrasqueira, trazendo simetria no móvel em marcenaria. Tanto no gourmet quanto na cozinha, a mesa de jantar envolve a ilha, mesclando formas orgânicas e curvas, transformando-os em espaços descontraídos para receber.",
      "A cozinha dispõe de uma adega totalmente iluminada que comporta 192 garrafas expostas, além das adegas refrigeradas, especialmente pensada para fãs de vinhos. Além de um louceiro envidraçado que completa a elegância agregada à funcionalidade do espaço.",
      "O lavabo segue a mesma linha estética da área social, além de ter um piso em seixos e um forro iluminado em tela tensionada."
    ],
    "suitePics": [
      "projects/APTO-GRIS/15.jpg",
      "projects/APTO-GRIS/16.jpg",
      {url: "projects/APTO-GRIS/17.jpg", wide: true},
      "projects/APTO-GRIS/18.jpg",
      "projects/APTO-GRIS/19.jpg",
      "projects/APTO-GRIS/20.jpg"
    ],
    "suiteTitle": "SUÍTE",
    "suiteAbout": [
      "Na suíte master trouxemos um ar mais rústico, seguindo a atmosfera aconchegante de todo o projeto. O revestimento orgânico usado no piso ditou a proposta dessa ampla suíte, usado juntamente com o tom mais quente de madeira da área social.",
      "A cuba integrada com a área do closet permite usos simultâneos com a área de banho e bacia sanitária."
    ]
  },
  {
    "_id": "6573cf28527000eccfc2e1e8",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/SUITE-BEAR/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/SUITE-BEAR/RENDER PRINCIPAL.jpg",
      "projects/SUITE-BEAR/2.jpg",
      "projects/SUITE-BEAR/3.jpg",
      "projects/SUITE-BEAR/4.jpg",
      "projects/SUITE-BEAR/6.jpg",
      "projects/SUITE-BEAR/5.jpg",
    ],
    "company": "SUÍTE BEAR - LF",
    "about": [
      "Tons suaves para transmitir calma em meio a atmosfera lúdica desse quartinho personalizado. Uma suíte funcional, estimulante e segura, com a cama baixa e um espaço de estudos para acompanhar o pequeno por anos. O banheiro segue a paleta com um toque lúdico nas pastilhas orgânicas."
    ]
  },
  {
    "_id": "6573cf28c1d3ec64cf7b3b08",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/APTO-NSEI/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/APTO-NSEI/RENDER PRINCIPAL.jpg",
      "projects/APTO-NSEI/1.jpg",
      "projects/APTO-NSEI/2.jpg",
      "projects/APTO-NSEI/3.jpg",
      "projects/APTO-NSEI/5.jpg",
      "projects/APTO-NSEI/6.jpg",
      "projects/APTO-NSEI/7.jpg",
      "projects/APTO-NSEI/8.jpg",
      "projects/APTO-NSEI/9.jpg",
      "projects/APTO-NSEI/10.jpg",
      "projects/APTO-NSEI/17.jpg",
      "projects/APTO-NSEI/13.jpg",
      "projects/APTO-NSEI/14.jpg",
      "projects/APTO-NSEI/15.jpg",
      "projects/APTO-NSEI/11.jpg",
      "projects/APTO-NSEI/12.jpg",
      "projects/APTO-NSEI/16.jpg"
    ],
    "company": "APTO VERT - JN",
    "about": [
      "Integração e sofisticação foram as chaves para esse projeto nos tons e na amplitude dessa área social, que pode ser inteiramente vista de qualquer cantinho, devido a toda a permeabilidade visual que essa planta permitiu. O diálogo entre os espaços foi feito além de fisicamente, esteticamente através dos materiais, que se repetem em toda a extensão do projeto.",
      "A leveza do mármore, juntamente com o peso da pedra rústica se equilibram, assim como a paleta de tons claros contrasta com os detalhes em preto.",
      "Ainda mantendo a integração entre os ambientes, os brises em muxarabi são divisórias sutis que trazem um toque de bossa que aquece o projeto paralelamente com a madeira.",
      "Um projeto pensado para receber, com áreas de estar, gourmet equipado com churrasqueira, chopeira, adega, cervejeira, grill, cooktop de apoio além de uma bancada com barzinho. Expor as garrafas de vinho nessa adega suspensa se torna uma arte na sala de jantar.",
      "A cozinha separada por portas de correr espelhadas é esteticamente limpa e leve, com eletrodomésticos tecnológicos em formato de galeria e refrigerador oculto que se contrapõem com o peso da ilha no mármore esculpido.",
      "O diálogo entre os materiais segue no lavabo e são destacados através da iluminação aconchegante da tela tensionada em toda a parede da bancada esculpida."
    ],
    "suitePics": [
      "projects/APTO-NSEI/18.jpg",
      { url: "projects/APTO-NSEI/19.jpg", wide: true },
      "projects/APTO-NSEI/20.jpg",
      "projects/APTO-NSEI/21.jpg",
      "projects/APTO-NSEI/22.jpg",
      { url: "projects/APTO-NSEI/23.jpg", wide: true },
      "projects/APTO-NSEI/24.jpg",
      "projects/APTO-NSEI/25.jpg",
      { url: "projects/APTO-NSEI/26.jpg", wide: true },
      "projects/APTO-NSEI/27.jpg"
    ],
    "suiteTitle": "SUÍTE",
    "suiteAbout": [
      "A suíte master é uma extensão estética da área social, o mesmo mármore georgette é trabalhado com leveza nesse ambiente todo integrado, moderno e amplo.",
      "Um closet totalmente envidraçado com iluminação em tela tensionada e uma ilha toda em mármore com bancada para maquiagem e cuba dupla.",
      "Ao lado da cama, um cantinho para descanso e leitura com lareira.",
      "Banheiro integrado com área de banheira de imersão e duchas abertas. Modernidade e elegância em cada detalhe."
    ]
  },
  {
    "_id": "67dde911addbf4d9d5849663",
    "isInHome": false,
    "released": true,
    "mainPicture": "projects/MEYRE/RENDER PRINCIPAL.jpg",
    "pictures": [
      "projects/MEYRE/RENDER PRINCIPAL.jpg",
      "projects/MEYRE/1.jpg",
      "projects/MEYRE/2.jpg",
      "projects/MEYRE/3.jpg",
      "projects/MEYRE/5.jpg",
      "projects/MEYRE/6.jpg",
      "projects/MEYRE/7.jpg",
      "projects/MEYRE/8.jpg",
      "projects/MEYRE/9.jpg",
      "projects/MEYRE/10.jpg",
      "projects/MEYRE/11.jpg",
      "projects/MEYRE/12.jpg",
      "projects/MEYRE/13.jpg",
      "projects/MEYRE/14.jpg",
      "projects/MEYRE/15.jpg",
      "projects/MEYRE/12.jpg",
      "projects/MEYRE/16.jpg",
      "projects/MEYRE/17.jpg",
      "projects/MEYRE/18.jpg",
      "projects/MEYRE/19.jpg"
    ],
    "company": "RESIDÊNCIA SERENA - ME",
    "about": [
      "Uma casa de dois pavimentos com a área social toda integrada no térreo permitiu um projeto com amplitude e conexão visual entre os ambientes. Com uma base neutra e moderna, todo o projeto foi idealizado com os mesmos materiais entre os ambientes para ressaltar a integração de toda a casa.",
      "O pedido principal da cliente foi uma casa que transmita tranquilidade, sossego e bem-estar, por isso o nome de Residência serena. O gourmet rodeado por portas de correr podendo integrar com toda a área externa, gerou um espaço muito amplo e delicioso para receber amigos e familiares!",
      "O lavabo é o ambiente perfeito para ousar um pouquinho mais e causar um impacto, já que é um ambiente aonde as visitas sempre vão quando visitam sua casa! Nesse lavabo vamos trocar o piso, a bancada, os revestimentos das paredes, instalar tudo novo e numa linha moderna, assim como a cliente pediu e eu adoro!",
      "Aproveitamos o espaço aberto no mezanino para criar um office moderno com cantinho de leitura com essa chaise super gostosa. A estante ficou um espetáculo à parte, essa iluminação em tela tensionada destaca tudo que for exposto na estante, sem contar no aconchego essa iluminação traz para o office!"
    ],
    "suitePics": [
      "projects/MEYRE/S101.jpg",
      "projects/MEYRE/S102.jpg",
      "projects/MEYRE/S103.jpg",
      "projects/MEYRE/S201.jpg",
      "projects/MEYRE/S202.jpg",
      "projects/MEYRE/S203.jpg",
      "projects/MEYRE/SH1.jpg",
      "projects/MEYRE/SH2.jpg",
      "projects/MEYRE/SH3.jpg",
      "projects/MEYRE/SM1.jpg",
      "projects/MEYRE/SM2.jpg",
      "projects/MEYRE/SM3.jpg",
      "projects/MEYRE/SM4.jpg",
      "projects/MEYRE/SM5.jpg",
      "projects/MEYRE/SM6.jpg",
      "projects/MEYRE/SM7.jpg",
      "projects/MEYRE/SM8.jpg",
      "projects/MEYRE/SM9.jpg",
      "projects/MEYRE/SM10.jpg",
      "projects/MEYRE/SM11.jpg",
      "projects/MEYRE/SM12.jpg",
    ],
    "suiteTitle": "SUÍTES",
    "suiteAbout": [
      "A suíte de hóspedes ficou bem neutra e aconchegante com detalhes lindos de iluminação. A iluminação é capaz de transformar os ambientes, podendo criar diferentes cenas apenas pelo acionamento individual das luminárias. Faz toda a diferença!",
      "A suíte 01 ousamos em tons neutros com contraste de preto e branco, com toques verdes nos detalhes. Novamente trabalhando destaque nas iluminações do ambiente, indispensável!",
      "A suíte 02 ficou bem aconchegante, com toque de madeira e um cinza mais escurinho que deram todo o charme! Amo a amplitude que o espelho dá nos ambientes, aqui trabalhamos nas portas do guarda-roupas!",
      "E por fim, a linda suíte master projetada para oferecer conforto, tranquilidade e sofisticação resultou nesse ambiente calmo, sereno e maravilhoso! Com painéis amadeirados que trazem um toque natural e sofisticado, a atmosfera do ambiente é ainda mais acolhedora. A cama baixa, além de moderna, cria uma sensação de amplitude e relaxamento! O closet integrado ao banheiro cria uma fluidez entre os espaços e uma sensação de amplitude. A penteadeira com iluminação em tela tensionada garante um toque glamouroso, e os armários espelhados ampliam o espaço e refletem a beleza do ambiente. Um banheiro amplo com esse conceito aberto traz toda uma experiência para o banho. A banheira de hidromassagem é ideal para momentos de relaxamento, e os vasos com galhos secos e seixos no piso reforçam essa paz e conforto, adicionando um toque natural ao ambiente."
    ]
  },
  {
    "_id": "67dde9d35d27e1f4fa83aed9",
    "isInHome": false,
    "released": true,
    "mainPicture": 'projects/APTO-LN/SALA 3.jpg',
    "pictures": [
      'projects/APTO-LN/SALA 3.jpg',
      'projects/APTO-LN/SALA 1.jpg',
      'projects/APTO-LN/SALA 2.jpg',
      'projects/APTO-LN/SALA 4.jpg',
      'projects/APTO-LN/COZINHA.jpg',
      'projects/APTO-LN/ESCRITÓRIO 1.jpg',
      'projects/APTO-LN/ESCRITÓRIO 2.jpg',
      'projects/APTO-LN/QUARTO 1.jpg',
      'projects/APTO-LN/QUARTO 2.jpg',
      'projects/APTO-LN/QUARTO 3.jpg',
      'projects/APTO-LN/BANHEIRO.jpg',
    ],
    "company": "APTO LN",
    "about": [
      "Vamos reformar todo esse apartamento antigo no centro da cidade de Londrina com os ambientes mais fechados, transformando a área social em um ambiente todo integrado!",
      "Nesse projeto fizemos uma demolição de um banheiro social para conseguirmos uma integração muito legal entre cozinha e sala, o que trouxe muita amplitude para o espaço. Amo como um projeto com base em tons neutros, pode ser cheio de vida e detalhes que trazem a personalidade da cliente! As mesmas cores e acabamentos usados por todo esse espaço trouxe ainda mais integração.Um quarto em tons mais escuros para um maior aconchego e jovialidade, com uma iluminação gostosa e aconchegante! Um destaque para essa bancada com penteadeira integrada já com iluminação direta, super funcional!"
    ]
  },
  {
    "_id": "67ddf4d99c3d1d821b481c5f",
    "isInHome": false,
    "released": true,
    "mainPicture": 'projects/SUITE-BLOOM/1.jpg',
    "pictures": [
      'projects/SUITE-BLOOM/1.jpg',
      'projects/SUITE-BLOOM/2.jpg',
      'projects/SUITE-BLOOM/3.jpg',
      'projects/SUITE-BLOOM/4.jpg',
      'projects/SUITE-BLOOM/5.jpg',
      'projects/SUITE-BLOOM/6.jpg',
      'projects/SUITE-BLOOM/7.jpg',
      'projects/SUITE-BLOOM/CAMINHA.jpg'
    ],
    "company": "SUÍTE BLOOM - FS",
    "about": [
      "Apresento a vocês esse quartinho de bebê encantador, onde cada detalhe foi pensado com muito carinho e amor. Em tons suaves de rosa e amadeirado, e com detalhes na marcenaria em palha indiana, o ambiente transmite conforto e acolhimento, além de design atemporal para seguir anos com a pequena.",
      "A iluminação em céu estrelado no forro de madeira e as luminárias em formato de flor são apenas alguns dos detalhes que fazem deste espaço uma verdadeira fofura. Para acompanhar o crescimento da bebê, o berço escolhido foi esse modelo evolutivo, e na última imagem do post temos a opção com a caminha quando fizer a substituição! Uma caminha baixa com suportes laterais estofadas, para evitar machucar nos momentos que ela rolar pela cama!",
      "Cada cantinho foi projetado para criar um ambiente mágico e sereno para a nova bebê da família. Espero que vocês gostem tanto quanto eu! Seguindo a proposta do quartinho em tons suaves e de rosa, o banheiro traz muita delicadeza em todos os detalhes! A combinação de revestimentos em meia parede tem como destaque esse rosado que tem uma paginação trabalhada e delicada, trazendo elegância e doçura para o espaço! O espelho, assim como o revestimento seguem a ideia do quartinho com as curvas e arredondados, que torna o conjunto ainda mais delicado e sofisticado na medida!"
    ]
  },
  {
    "_id": "67ddf4d979471f1e3c0e3435",
    "isInHome": false,
    "released": true,
    "mainPicture": 'projects/APTO-AREIA/SALA (1).jpg',
    "pictures": [
      'projects/APTO-AREIA/SALA (1).jpg',
      'projects/APTO-AREIA/SALA (2).jpg',
      'projects/APTO-AREIA/SALA (3).jpg',
      'projects/APTO-AREIA/COZINHA (1).jpg',
      'projects/APTO-AREIA/COZINHA (2).jpg',
      'projects/APTO-AREIA/OFFICE (1).jpg',
      'projects/APTO-AREIA/OFFICE (2).jpg',
      'projects/APTO-AREIA/QUARTO (1).jpg',
      'projects/APTO-AREIA/QUARTO (2).jpg',
      'projects/APTO-AREIA/QUARTO (3).jpg',
      'projects/APTO-AREIA/QUARTO (4).jpg',
      'projects/APTO-AREIA/I.S. 01 (1).jpg',
      'projects/APTO-AREIA/I.S. 01 (2).jpg',
      'projects/APTO-AREIA/SUÍTE (1).jpg',
      'projects/APTO-AREIA/SUÍTE (2).jpg',
      'projects/APTO-AREIA/SUÍTE (3).jpg',
      'projects/APTO-AREIA/I.S. SUÍTE (1).jpg',
      'projects/APTO-AREIA/I.S. SUÍTE (2).jpg',
    ],
    "company": "APTO AREIA - RL",
    "about": [
      "A área social toda integrada e trabalhada em tons de areia e no amadeirado claro, com um toque de bronze e para quebrar, detalhes de metais no preto. Aproveitando a maior parede para acomodar um sofá grande, a tv fica suspensa nesse painel marmorizado, com o rack ripado abaixo. Muita funcionalidade e design! Amo como ambientes mais clarinhos podem ficar super aconchegantes nesse tom mais quente, ainda mais adicionando detalhes no bronze! Os tons podem ser trabalhados para despertar diferentes sensações, aqui a ideia foi tranquilidade e aconchego!",
      "O office ficou muito funcional, seguindo a mesma paleta do restante do projeto!",
      "O quarto da filha ficou numa paleta bem neutra com detalhes que combina o areia com um tom de doce de leite.Aqui foram os detalhes curvos e circulares que trouxeram um toque especial para o espaço.Nos cabides, na arandela, nos puxadores do armário, nos vasos, na cadeira, no painel e nas prateleiras.Esses detalhes contextualizam cada escolha para o espaço, trazendo personalidade para o projeto!",
      "A suíte do casal foi trabalhada também em tons neutros que transmitem muita leveza, com detalhes na serralheria preta! Detalhes de roupa de cama nesse tom doce de leite, assim como a cortina mais escura, trazendo pontos de leve contraste!"
    ]
  },
  {
    "_id": "67ddf4e67d7b67ea8085da7d",
    "isInHome": false,
    "released": true,
    "company": "RESIDÊNCIA PETRA - TA",
    "mainPicture": 'projects/RESIDENCIA-PETRA/SALA ESTAR 1.jpg',
    "pictures": [
      'projects/RESIDENCIA-PETRA/SALA ESTAR 1.jpg',
      'projects/RESIDENCIA-PETRA/SALA ESTAR 2.jpg',
      'projects/RESIDENCIA-PETRA/SALA ESTAR 3.jpg',
      'projects/RESIDENCIA-PETRA/SALA DE JANTAR 1.jpg',
      'projects/RESIDENCIA-PETRA/SALA DE JANTAR 2.jpg',
      'projects/RESIDENCIA-PETRA/SALA DE JANTAR 3.jpg',
      'projects/RESIDENCIA-PETRA/COZINHA.jpg',
      'projects/RESIDENCIA-PETRA/GOURMET 1.jpg',
      'projects/RESIDENCIA-PETRA/GOURMET 2.jpg',
      'projects/RESIDENCIA-PETRA/LAVABO 1.jpg',
      'projects/RESIDENCIA-PETRA/LAVABO 2.jpg'
    ],
    "about": [
      "A área social integrada foi trabalhada em tons neutros de cinza com destaque para a parede em pedra moledo branca, trazendo muito aconchego e sofisticação ao ambiente. A pedra moledo por si só traz uma sensação de natureza, organicidade e aconchego, além de dar personalidade e caracterizar o projeto.Eu sou apaixonada pelo efeito que ela entrega! A madeira e o espelho bronze ajudam a aquecer o ambiente mais voltado para os tons cinzas, equilibrando tudo muito bem.A cozinha super funcional fica ampla pela integração com a sala de jantar e a sala de estar, criando conexão entre os ambientes."
    ],
    "suiteTitle": "SUÍTES",
    "suiteAbout": [
      "Essa residência conta com três suítes, uma para a filha, uma para o filho e a outra para o casal.A suíte da filha ficou bem feminina e linda, com tons claros e muito rosa, além de detalhes delicados em serralheira, e uma cama toda em gomos para trazer muito conforto e ludicidade, uma gracinha.A suíte do filho trabalhamos com detalhes em verde, madeira clara e serralheria preta, a cama baixa fica aconchegante e segura para o pequeno.", 
      "Na suíte master do casal abusamos dos tons neutros e frios, buscamos uma linguagem elegante e aconchegante ao mesmo tempo.A iluminação da tela tensionada para penteadeira é uma ótima luz para maquiagem e ao mesmo tempo uma luz confortável que lateralizada à cama, traz muito aconchego.O banheiro nos mesmos tons e acabamentos para trazer uma unidade.Detalhe importante!"
    ],
    "suitePics": [
      'projects/RESIDENCIA-PETRA/SUÍTE LUIZA 1.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE LUIZA 2.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE LUIZA 3.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE LUIZA 4.jpg',
      'projects/RESIDENCIA-PETRA/I.S. SUÍTE LUIZA 1.jpg',
      'projects/RESIDENCIA-PETRA/I.S. SUÍTE LUIZA 2.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE SAMUEL 1.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE SAMUEL 2.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE SAMUEL 3.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE SAMUEL 4.jpg',
      'projects/RESIDENCIA-PETRA/I.S. SUÍTE SAMUEL 1.jpg',
      'projects/RESIDENCIA-PETRA/I.S. SUÍTE SAMUEL 2.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE MASTER 1.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE MASTER 2.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE MASTER 3.jpg',
      'projects/RESIDENCIA-PETRA/SUÍTE MASTER 4.jpg',
      'projects/RESIDENCIA-PETRA/I.S. MASTER 1.jpg',
      'projects/RESIDENCIA-PETRA/I.S. MASTER 2.jpg'
    ]
  },
  {
    "_id": "67ddf4e634b0bc579ef0089d",
    "isInHome": false,
    "released": true,
    "mainPicture": 'projects/APTO-PAZ/SALA (3).jpg',
    "pictures": [
      'projects/APTO-PAZ/SALA (3).jpg',
      'projects/APTO-PAZ/SALA (1).jpg',
      'projects/APTO-PAZ/SALA (2).jpg',
      'projects/APTO-PAZ/SALA (4).jpg',
      'projects/APTO-PAZ/SALA (5).jpg',
      'projects/APTO-PAZ/COZINHA (1).jpg',
      'projects/APTO-PAZ/COZINHA (2).jpg',
      'projects/APTO-PAZ/COZINHA (3).jpg',
      'projects/APTO-PAZ/COZINHA (4).jpg',
      'projects/APTO-PAZ/LAVANDERIA.jpg',
      'projects/APTO-PAZ/CIRCULAÇÃO.jpg',
      'projects/APTO-PAZ/I.S. 01 (1).jpg',
      'projects/APTO-PAZ/I.S. 01 (2).jpg'
    ],
    "company": "APTO PAZ - CA",
    "about": [
      "Esse projeto é uma reforma em um apartamento antigo na região central de Londrina.Os clientes solicitaram um projeto com objetivo de modernizar o apartamento com elementos que trazem conforto e paz.Na sala de estar priorizamos tons neutros com toques suaves de cor, além dos painéis em madeira que trazem aconchego.Fizemos um cantinho com poltronas ótimo para descansar lendo um livro com a vista da cidade.A estante foi pensada para acomodar os itens de viagem que os clientes vêm colecionando ao longo da vida.",
      "A cozinha é um ambiente de uso diário que precisa ser muito funcional, aqui priorizamos as necessidades do cliente para o que fica funcional para eles.A cozinha ficou ampla e já com a mesa de jantar ali mesmo.Dela, temos acesso à lavanderia que ficou simples, funcional e bonita."
    ],
    "suiteTitle": "QUARTOS",
    "suiteAbout": [
      "Nesse apartamento temos dois quartos de hóspedes e a suíte master do casal.Um dos quartos de hóspedes ficou em tons mais terrosos com um detalhe na cabeceira para encaixar luminárias. O simples com um detalhe que faz toda diferença.O outro quarto para receber visitas a bicama para acomodar duas pessoas quando necessário, super prático, além do espaço para home office.",
      "A suíte master ficou muito aconchegante e elegante, trabalhamos em tons neutros e muita madeira! Os detalhes de iluminação fazem toda a diferença, criamos uma sanca no forro de gesso que segue para o painel da parede, além das arandelas lindas que permitem uma iluminação aconchegante, que é fundamental para um quarto."
    ],
    "suitePics": [
      'projects/APTO-PAZ/QUARTO 01 (1).jpg',
      'projects/APTO-PAZ/QUARTO 01 (2).jpg',
      'projects/APTO-PAZ/QUARTO 01 (3).jpg',
      'projects/APTO-PAZ/QUARTO 01 (4).jpg',
      'projects/APTO-PAZ/QUARTO 02 (1).jpg',
      'projects/APTO-PAZ/QUARTO 02 (2).jpg',
      'projects/APTO-PAZ/QUARTO 02 (3).jpg',
      'projects/APTO-PAZ/QUARTO 02 (4).jpg',
      'projects/APTO-PAZ/SUÍTE (1).jpg',
      'projects/APTO-PAZ/SUÍTE (2).jpg',
      'projects/APTO-PAZ/SUÍTE (3).jpg',
      'projects/APTO-PAZ/SUÍTE (4).jpg',
      'projects/APTO-PAZ/I.S. SUÍTE (1).jpg',
      'projects/APTO-PAZ/I.S. SUÍTE (2).jpg',
    ]
  },
  {
    "_id": "67ddf4e6f60e9aa12d57da8f",
    "isInHome": true,
    "released": true,
    "mainPicture": 'projects/APTO-CAPUCCINO/SALA (1).jpg',
    "pictures": [
      'projects/APTO-CAPUCCINO/SALA (1).jpg',
      'projects/APTO-CAPUCCINO/SALA (2).jpg',
      'projects/APTO-CAPUCCINO/SALA (3).jpg',
      'projects/APTO-CAPUCCINO/SALA (4).jpg',
      'projects/APTO-CAPUCCINO/COZINHA (1).jpg',
      'projects/APTO-CAPUCCINO/JANTAR (1).jpg',
      'projects/APTO-CAPUCCINO/I.S. 01 (1).jpg',
      'projects/APTO-CAPUCCINO/I.S. 01 (2).jpg'
    ],
    "company": "APTO CAPUCCINO - LO",
    "about": [
      "Esse apartamento(Vibe - Yticon) tem uma área social ampla ótima para receber, nele trabalhamos em tons de fendi, cinza e capuccino, um mix neutro mas que traz muito aconchego para o espaço.Ao centro da sala de estar trabalhamos uma composição de mesa de centro com um puff e dois banquinhos para servir de apoio para receber visitas, fica super prático e lindo.",
      "Nessa área social utilizamos o muxarabi, esse trabalhado na marcenaria lindo que encanta! Usamos no rack, no forro da área da mesa de jantar e no painel iluminado atrás do sofá.Amo como repetir um elemento unifica todo o ambiente.",
      "Nesse espaço temos integração da sala de estar, da sala de jantar e da cozinha.Mesmo sendo um espaço de cozinha mais compacto, todos os eletros desejados foram pensados e encaixados para atender as necessidades da cliente no dia a dia.",
      "Banheiro social seguindo a mesma proposta que a sala de estar e jantar com o muxarabi.Aqui temos uma iluminação indireta para valorizar ainda mais esse trabalho em marcenaria! Substituímos a bancada entregue pela construtora por essa maravilhosa em mármore para impressionar! Lindo, funcional e sofisticado."
    ],
    "suiteTitle": "QUARTOS",
    "suiteAbout": [
      "Um quarto de visitas muito especial para receber a sobrinha.Nele utilizamos a madeira e detalhes em tons de verde para deixar bem aconchegante, ficou uma gracinha.",
      "Suíte master em tons de capuccino com fendi, uma combinação elegante e sofisticada que traz uma sensação de conforto muito gostosa para esse espaço tão especial da casa.A planta desse apartamento tem esse cantinho na suíte que permite um closet aberto.Lindo e super prático, fizemos uma porta de correr para proteger as peças guardadas ali.",
      "No banheiro da suíte também foi feita a troca dos acabamentos entregues pela construtora para valorizar ainda mais o espaço, seguindo os mesmos tons do quarto."
    ],
    "suitePics": [
      'projects/APTO-CAPUCCINO/QUARTO (1).jpg',
      'projects/APTO-CAPUCCINO/QUARTO (2).jpg',
      'projects/APTO-CAPUCCINO/QUARTO (3).jpg',
      'projects/APTO-CAPUCCINO/QUARTO (4).jpg',
      'projects/APTO-CAPUCCINO/SUÍTE (1).jpg',
      'projects/APTO-CAPUCCINO/SUÍTE (2).jpg',
      'projects/APTO-CAPUCCINO/SUÍTE (3).jpg',
      'projects/APTO-CAPUCCINO/SUÍTE (4).jpg',
      'projects/APTO-CAPUCCINO/SUÍTE (5).jpg',
      'projects/APTO-CAPUCCINO/I.S. SUÍTE (1).jpg',
      'projects/APTO-CAPUCCINO/I.S. SUÍTE (2).jpg'
    ]
  }
]

export const projectsInHome: Array<any> = projectData.filter(item=>item.isInHome)
export const allProjects: Array<any> = projectData

export const getProjectDetails = (projectId?: string)=> projectId? projectData.filter(item=>item._id === projectId)[0]: null

export type ProjectType = {
  _id: string;
  isInHome: boolean;
  mainPicture: string;
  pictures: string[] | {url: string, wide: boolean}[];
  company: string;
  about: string;
  suiteAbout?: string[];
  suiteTtitle?: string;
  suitePics?: string[] | { url: string, wide: boolean }[];
  released: boolean;
}